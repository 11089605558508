const en = {
    bookDetail: {
        addSteps: "Añadir pasos",
        bookDetails: "Detalles del libro",
        completed: "¡Completado {on}!",
        done: "¡Terminé!",
        editThisBook: "Editar este libro",
        keepReading: "¡Sigue leyendo!",
        lastRead: "Leído {on}",
        pausedOn: "Pausado en {on}",
        readingLog: "Registro de lectura",
        setPaused: "Pausar este libro",
        setReading: "Regresar a Leyendo ahora",
        unpause: "Reanudar"
    },
    carousel: {
        becauseYouLike: "Como te gusta {genre}",
        hookBooks: "Libros con gancho recomendados para ti",
        title: "Libros que quizás te gusten"
    },
    genreChart: {
        genreCategory: "Tipos de géneros"
    },
    genres: {
        "Adventure / Survival": "Aventura/Supervivencia",
        "Animal Stories": "Historias de animales",
        "Biography": "Biografía",
        "Cont / Realistic Fiction": "Ficción realista/contemporánea",
        "Drama / Play": "Obras de teatro",
        "Dystopia": "Distopia",
        "Editorial/Position Piece": "Editorial/Ensayo persuasivo",
        "Exposé": "Textos expositivos",
        "Fantasy": "Fantasía",
        "Historical Fiction": "Ficción histórica",
        "Horror": "Horror",
        "Humor / Silly": "Humor",
        "Informational": "Textos informativos",
        "Instructional Man": "Manual de instrucciones",
        "Magical Realism": "Realismo mágico",
        "Memoir": "Memorias",
        "Mystery": "Misterio",
        "Other": "Otros",
        "Poetry": "Poesía",
        "Primary Document": "Documento primario",
        "Realistic Fiction": "Ficción realista",
        "Religion / Spirituality": "Texto religioso",
        "Romance": "Romance",
        "Science Fiction": "Ciencia ficción",
        "Self Help / Guidance": "Autoayuda",
        "Traditional Tales": "Cuentos tradicionales"
    },
    global: {
        cancelButton: "Cancelar",
        closeButton: "Cerrar",
        error404: "Error 404",
        error500: "Error 500",
        na: "N/A",
        status: {
            "Reviewing": "Revisando",
            "Submitted": "Enviado",
            "Approved": "Aprobado",
            "Paused": "Pausado",
            "Rejected": "Comprobar"
        }
    },
    menu: {
        logbook: "Mi registro",
        assessment: "Mi evaluación",
        assignments: "Mis tareas",
        bookshelf: "Visitar ARC Bookshelf",
        help: "Ayuda",
        selectLanguage: "Escoge un idioma",
        settings: "Mis configuraciones",
        logout: "Cerrar sesión",
        home: "Inicio"
    },
    milestones: {
        almostThere: "¡Échale ganas, {name}! ¡Ya casi llegas a tu primer logro!",
        greatWork: "¡Buen trabajo, {name}!",
        steps: "pasos",
        stepsProgressBar: "Barra de progreso de pasos",
        stepsReached: "¡Alcanzaste la meta de un paso!|¡Alcanzaste la meta de {n} pasos!",
        stepsUnreviewed: "Todos los pasos han sido revisados|1 paso no ha sido revisado|{n} pasos no han sido revisados"
    },
    modals: {
        bookshelf: {
            startReading: "¡Comienza a leer!",
            title: "Llévame a ARC Bookshelf",
        },
        help: {
            title: "Ayuda",
            tutorialHeader: "Tutorial para usar el <b>Informe de lectura</b> ARC Reads (en inglés)",
            tutorialContent: "Aprende a utilizar tu informe de lectura digital. Aquí puedes añadir los libros digitales o físicos que estás leyendo, registrar tu práctica de lectura y descubrir libros nuevos para leer.",
            helpHeader: "¿Necesitas más ayuda? ¡Habla con tu maestro/a!",
            helpContent: "Si necesitas más ayuda, dile a tu maestro/a qué estás intentando hacer y descríbele el problema que estás teniendo con tu <b>Informe de lectura</b> ARC Reads."
        },
        logReading: {
            addSteps: "Añade pasos",
            addToLogbook: "Añade a mi registro",
            author: "Autor",
            authorRequired: "Requisito: ¿Quién es el autor o autora?",
            bookTitle: "Título del libro",
            isbn: "ISBN",
            logReading: "Registra la lectura",
            optional: "Opcional",
            pageCount: "¿Cuántas páginas tiene este libro?",
            stepsRequired: "Requisito: ¿Cuántos pasos leíste?",
            tellUs: "Cuéntanos acerca de tu libro",
            titleRequired: "Requisito: ¿Cuál es el título del libro?"
        },
        readingComplete: {
            greatWork: "¡Buen trabajo, {name}!",
        },
        viewLog: {
            action: "Acción",
            dateRead: "Fecha de lectura",
            delete: "Borrar",
            deleteConfirmText: "¿Estás seguro/a que quieres borrar estos pasos?",
            deleteConfirmTitle: "¿Quieres borrar los pasos?",
            importInfo: "Los pasos leídos en ARC Bookshelf no aparecerán automáticamente. Se actualizarán en tu registro al comienzo del día.",
            movingToPaused: "Pasando a libros en pausa...",
            readingLog: "Registro de lectura",
            startedReadingOn: "Comenzó a leer en {when}",
            status: "Estado",
            stepsRead: "Pasos leídos",
        }
    },
    moment: {
        default: "DD/MM/YYYY",
        lastWeek: "dddd [pasado]",
        nextWeek: "dddd",
        today: "[hoy]",
        tomorrow: "[Mañana]",
        yesterday: "[Ayer]",
    },
    pages: {
        arcid: {
            authenticating: "Autenticando...",
            badCode: "Código de autorización inválido. Por favor intente de nuevo.",
            pleaseWait: "Por favor espere a que su cuenta sea autenticada.",
            unable: "No es posible iniciar la sesión",
            somethingWrong: "Algo anda mal con su ARC ID. Por favor intente de nuevo."
        },
        assessment: {
            myAssessment: "Mi <b>evaluación</b>",
            startAssessment: "¡Comience la evaluación!",
            assessmentResults: "Assessment Results",
            assessment: "Evaluación",
            filterAll: "All",
            tips: "Tips",
            grade: "Grade",
            classroom: "Classroom"
        },
        collection: {
            collectionAward: {
                schoolYear: "Año escolar"
            },
            collectionFooter: {
                date: "Fecha",
                recipient: "Otorgado a",
            },
            collectionStats: {
                completedBooks: "Libros completados",
                hoursRead: "Horas de lectura",
                longestStreak: "Racha más larga",
                totalSteps: "Total de pasos",
            },
            grid: {
                chooseBg: "Escoge un color de fondo",
                dateRange: "Fechas de libros completados",
                default: "Por defecto",
                schoolYear: "Año escolar",
                selectFrame: "Selecciona un cuadro",
                nameCollection: "Nombra tu colección"
            },
        },
        error404: {
            altText: "No se encontró la página",
            contents: "Parece que no hay nada aquí",
            returnHome: "Regresar a la página principal"
        },
        error500: {
            heading: "Error de sistema",
            home: "Página principal",
            returnHome: "Ir a la página principal",
            somethingWrong: "¡Algo salió mal!",
            tryAgain: "Por favor intente más tarde."
        },
        home: {
            emptyReading: "Cuando termines de leer un libro, aparecerá aquí.",
            nowReading: "Leyendo ahora",
            pageTitle: "Mi registro",
            pausedBooks: "Libros en pausa",
            recentlyCompleted: "Libros completados recientemente",
            visitLogbook: "Visitar <b>Mi registro</b>",
            visitAssessment: "Visitar <b>Mi evaluación</b>",
            visitAssignments: "Visitar <b>Mi Asignaciones</b>"
        },
        login: {
            header: "Iniciar sesión de ARC Reads"
        },
        logout: {
            header: "Se ha cerrado la sesión."
        },
        noAccess: {
            header: "Favor de pedir acceso",
            requestAccess: "Para usar ARC Reads, favor de pedir acceso a su escuela o al administrador del distrito."
        },
        profile: {
            category: {
                "Colors": "Colores",
                "Nature": "Naturaleza",
                "Animals": "Animales"
            },
            charactersRemaining: "caracteres restantes",
            chooseAvatar: "Escoge un avatar",
            displayNameLabel: "Añade tu nombre de usuario",
            genreHeading: "Escoge tus géneros favoritos",
            heading: "Edita tu perfil",
            pickTheme: "Escoge un tema",
            saveAndGo: "Guardar y añadir a mi registro",
            saving: "Guardando cambios..."
        },
        stats: {
            genresRead: {
                header: "Todos los géneros que he leído",
            },
            readingStreak: {
                day: "día",
                header: "Racha de lectura",
                streakNoSteps: "Lee {minSteps} pasos hoy para {continueStart} la racha.",
                streakInProgress: "¡Ya casi! Lee {remainingSteps} pasos más hoy para {continueStart} la racha.",
                streakSatisfied: "¡Buen trabajo hoy! Sigue leyendo {minSteps} pasos todos los días para continuar tu racha."
            },
            stepCount: {
                conversion: "1 paso = 15 minutos de lectura",
                header: "Cantidad de pasos",
                monthly: "Por mes",
                lifetime: "Trayectoria",
                since: "Desde ",
                steps: "Pasos",
                weekly: "Por semana",
                yearly: "Por año",
            },
            stepsChart: {
                day: "Día",
                header: "¿Cuántos pasos he leído este {period}?",
                month: "mes",
                monthly: "Por mes",
                numberOfSteps: "# de pasos",
                week: "semana",
                weekly: "Por semana"
            },
            todaysSteps: {
                header: "Pasos de hoy"
            }
        }
    },
    ratings: {
        rateThisBook: "Reseña este libro",
        yourRating: "Tu calificación"
    },
    searchbar: {
        findBooks: "Encuentra libros para añadir a mi registro"
    },
    statsPanel: {
        approved: "Aprobados",
        currentStreak: "Racha actual",
        emptyGenresRead: "¡Termina un libro para ver a qué género pertenece!",
        genresRead: "Géneros que he leído",
        lifetimeSteps: "Trayectoria",
        milestone: "Logro",
        milestoneMedals: "Medallas",
        schoolYear: "Año Escolar ",
        sinceSchoolYear: "Desde el año escolar ",
        todaysSteps: "Pasos de hoy",
        totalSteps: "Total de pasos",
        findBooks: "Encuentra libros para añadir a mi registro",
        unapproved: "No aprobados",

    },
    stepsForm: {
        addYourSteps: "Añade tus pasos",
        dateDescription: "Fecha de hoy",
        dateLabel: "Fecha",
        lastPageLabel: "Última página leída",
        lastPageDescription: "Opcional",
        lastPagePlaceholder: "Añade # de página",
        steps: "{n} paso|{n} pasos",
        stepsLabel: "Pasos",
        stepsToMinutes: "1 paso = 15 minutos"
    },
    stepsRead: {
        steps: "paso|pasos",
    },
    toolbar: {
        backTo: "Regresar a ",
        bookCollection: "Mi colección de libros</b>",
        logbook: "Mi registro",
        moreStats: "Más de <b>mi progreso</b>",
        shareCollection: "¡Comparte tu colección de libros!",
        print: "Imprimir"
    },
    typeahead: {
        cantFind: "¿No puedes encontrar lo que buscabas?",
        tellUs: "Cuéntanos acerca de tu libro"
    }
};

export default en;