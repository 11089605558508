import { UnleashClient } from 'unleash-proxy-client';
import store from "@/core/store/PersistentStorage";

const unleash = new UnleashClient({
  url: process.env.VUE_APP_UNLEASH_URI,
  clientKey: process.env.VUE_APP_UNLEASH_KEY,
  appName: 'arc-reads'
});

if (store.authentication.user?.districtId) {
  unleash.setContextField('districtId', store.authentication.user.districtId);
}
else {
  unleash.setContextField('districtId', '');
}

if (store.authentication.user?.currentInstance?.gradeId) {
  unleash.setContextField('gradeId', store.authentication.user.currentInstance.gradeId);
}
else {
  unleash.setContextField('gradeId', '');
}

export default unleash;