
import {defineComponent, nextTick, onMounted} from "vue";
import {useStore} from "vuex";
import {Mutations} from "@/core/store/enums/StoreEnums";
import {initializeComponents} from "@/core/plugins/arcui";
import eventBus from "@/core/services/eventBus";
import authenticationService from "@/core/services/AuthenticationService";
import {useRoute, useRouter} from "vue-router";
import bookService from "@/app/services/bookService";
import arcidService from "@/app/services/arcidService";
import logService from "@/app/services/LogService";

export default defineComponent({
  name: "app",
  mounted() {
    if (localStorage.Lang != null) this.$i18n.locale = localStorage.Lang;
    else localStorage.Lang = this.$i18n.locale = "en";
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */

      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });

      eventBus.$on('loggedIn', () => {
        bookService.loadGenres(authenticationService.currentUser().irla || null, null);
        bookService.loadRecommendations();
        bookService.loadBookshelfSubscriptions();
        logService.loadSchoolYears();
      });

      eventBus.$on('logout', () => {
        console.log("LOGOUT EVENT");
        authenticationService.logout();
      });

      // When we deserialize the store (e.g. app load) try to get a new access token right away.
      // This will also start a timer to refresh periodically to keep the session open.

      eventBus.$on('storeLoaded', () => {
        // Grab a new access token
        window.setTimeout(() => {
          authenticationService.getNewAccessToken();
        }, 300);
      });

      // Periodically call ARC ID to ensure the user stays logged in.
      window.setInterval(() => {
        arcidService.extendSession();
      }, 5 * 60 * 1000);
    });
  },
});
