 const en = {
    bookDetail: {
        addSteps: "Add Steps",
        bookDetails: "Book Details",
        completed: "Completed {on}!",
        done: "I'm done!",
        editThisBook: "Edit this book",
        keepReading: "Keep Reading!",
        lastRead: "Last Read {on}",
        pausedOn: "Paused on {on}",
        readingLog: "Reading Log",
        setPaused: "Pause this book",
        setReading: "Move back to Now Reading",
        unpause: "Unpause"
    },
    carousel: {
        becauseYouLike: "Because you like {genre}",
        hookBooks: "Hook Books we think you'll love",
        title: "Books you might like"
    },
    genreChart: {
        genreCategory: "Genre Category"
    },
    genres: {
        "Adventure / Survival": "Adventure / Survival",
        "Animal Stories": "Animal Stories",
        "Biography": "Biography",
        "Cont / Realistic Fiction": "Contemporary Realistic Fiction",
        "Drama / Play": "Drama / Play",
        "Dystopia": "Dystopia",
        "Editorial/Position Piece": "Editorial/Position Piece",
        "Exposé": "Exposé",
        "Fantasy": "Fantasy",
        "Historical Fiction": "Historical Fiction",
        "Horror": "Horror",
        "Humor / Silly": "Humor / Silly",
        "Informational": "Informational",
        "Instructional Man": "Instructional Man.",
        "Magical Realism": "Magical Realism",
        "Memoir": "Memoir",
        "Mystery": "Mystery",
        "Other": "Other",
        "Poetry": "Poetry",
        "Primary Document": "Primary Document",
        "Realistic Fiction": "Realistic Fiction",
        "Religion / Spirituality": "Religion / Spirituality",
        "Romance": "Romance",
        "Science Fiction": "Science Fiction",
        "Self Help / Guidance": "Self Help / Guidance",
        "Traditional Tales": "Traditional Tales"
    },
    global: {
        cancelButton: "Cancel",
        closeButton: "Close",
        error404: "404 Error",
        error500: "500 Error",
        na: "N/A",
        status: {
            "Reviewing": "Reviewing",
            "Submitted": "Submitted",
            "Approved": "Approved",
            "Paused": "Paused",
            "Rejected": "Check In"
        }
    },
    menu: {
        logbook: "My Logbook",
        assessment: "My IRLA",
        assignments: "My Assignments",
        bookshelf: "Visit ARC Bookshelf",
        help: "Help",
        selectLanguage: "Select a language",
        settings: "My Settings",
        logout: "Log Out",
        home: "Home",
    },
    milestones: {
        almostThere: "Keep up the great work, {name}! You're almost at the first milestone!",
        greatWork: "Great work, {name}!",
        steps: "steps",
        stepsProgressBar: "Steps Progress Bar",
        stepsReached: "You've reached the one step milestone!|You've reached the {n} steps milestone!",
        stepsUnreviewed: "No unreviewed steps|1 step not yet reviewed|{n} steps not yet reviewed"
    },
    modals: {
        bookshelf: {
            startReading: "Start Reading!",
            title: "Take me to ARC Bookshelf",
        },
        help: {
            title: "Help",
            tutorialHeader: "ARC Reads <b>Logbook</b> Tutorial (in English)",
            tutorialContent: "Learn to use your digital logbook. Here you can add any ebooks or print books you are currently reading, record reading practice, and discover new books to read!",
            helpHeader: "Still need help? Ask your Teacher!",
            helpContent: "If you still need help, please tell your teacher what you’re trying to do, and describe any problems you’re having in the ARC Reads <b>Logbook</b>."
        },
        logReading: {
            addSteps: "Add Steps",
            addToLogbook: "Add to My Logbook",
            author: "Author",
            authorRequired: "Required: Who is the author?",
            bookTitle: "Book Title",
            isbn: "ISBN",
            logReading: "Log Reading",
            optional: "Optional",
            pageCount: "How many pages does this book have?",
            stepsRequired: "Required: How many steps did you read?",
            tellUs: "Tell us about your book",
            titleRequired: "Required: What is the book title?"
        },
        readingComplete: {
            greatWork: "Great work, {name}!",
        },
        viewLog: {
            action: "Action",
            dateRead: "Date Read",
            delete: "Delete",
            deleteConfirmText: "Are you sure you want to delete these steps?",
            deleteConfirmTitle: "Delete Steps?",
            importInfo: "Steps read in ARC Bookshelf will not show up automatically. They will be updated in your logbook every morning.",
            movingToPaused: "Moving to paused books...",
            readingLog: "Reading Log",
            startedReadingOn: "Started reading on { when }",
            status: "Status",
            stepsRead: "Steps Read",
        }
    },
    moment: {
        default: "MM/DD/YYYY",
        lastWeek: "[Last] dddd",
        nextWeek: "dddd",
        today: "[Today]",
        tomorrow: "[Tomorrow]",
        yesterday: "[Yesterday]",
    },
    pages: {
        arcid: {
            authenticating: "Authenticating...",
            badCode: "Missing or invalid authorization code. Please try again.",
            pleaseWait: "Please wait while your account is authenticated.",
            unable: "Unable to log in",
            somethingWrong: "Something went wrong with your ARC ID login. Please try again."
        },
        assessment: {
            myAssessment: "My <b>IRLA</b>",
            startAssessment: "Start",
            assessmentResults: "Results",
            assessment: "Attempt",
            filterAll: "All",
            tips: "Tips",
            grade: "Grade",
            classroom: "Classroom"
        },
        collection: {
            collectionAward: {
                schoolYear: "School Year"
            },
            collectionFooter: {
                date: "Date",
                recipient: "Recipient",
            },
            collectionStats: {
                completedBooks: "Completed Books",
                hoursRead: "Hours Read",
                longestStreak: "Longest Streak",
                totalSteps: "Total Steps",
            },
            grid: {
                chooseBg: "Choose a background color",
                dateRange: "Date range when you completed books",
                default: "Default",
                schoolYear: "School Year",
                selectFrame: "Select a frame",
                nameCollection: "Name your collection"
            },
        },
        error404: {
            altText: "Page not found",
            contents: "Seems there is nothing here",
            returnHome: "Return Home"
        },
        error500: {
            heading: "System Error",
            home: "Home",
            returnHome: "Go to homepage",
            somethingWrong: "Something went wrong!",
            tryAgain: "Please try again later."
        },
        home: {
            emptyReading: "When you finish reading a book, it will show up here.",
            nowReading: "Now reading",
            pageTitle: "My Logbook",
            pausedBooks: "Paused books",
            recentlyCompleted: "Recently completed books",
            visitLogbook: "Visit <b>My Logbook</b>",
            visitAssessment: "Visit <b>My IRLA</b>",
            visitAssignments: "Visit <b>My Assignments</b>"
        },
        login: {
            header: "Login to ARC Reads"
        },
        logout: {
            header: "You have been logged out."
        },
        noAccess: {
            header: "Please Request Access",
            requestAccess: "To use ARC Reads, please request access from your school or district administrator."
        },
        profile: {
            category: {
                "Colors": "Colors",
                "Nature": "Nature",
                "Animals": "Animals"
            },
            charactersRemaining: "characters left",
            chooseAvatar: "Choose an avatar",
            displayNameLabel: "Add a display name",
            genreHeading: "Select your favorite genres",
            heading: "Edit your profile",
            pickTheme: "Pick a theme",
            saveAndGo: "Save Settings",
            saving: "Saving Changes..."
        },
        stats: {
            genresRead: {
                header: "All of the Genres I've Read",
            },
            readingStreak: {
                day: "day",
                header: "Reading Streak",
                streakNoSteps: "Read {minSteps} steps today to {continueStart} streak.",
                streakInProgress: "Almost there! Read {remainingSteps} more step{s} today to {continueStart} streak.",
                streakSatisfied: "Great work today! Keep reading {minSteps} steps every day to continue your streak."
            },
            stepCount: {
                conversion: "1 step = 15 minutes of reading",
                header: "Step Count",
                lifetime: "Lifetime",
                monthly: "Monthly",
                since: "Since ",
                steps: "Steps",
                weekly: "Weekly",
                yearly: "This School Year",
            },
            stepsChart: {
                day: "Day",
                header: "How Many Steps Have I Read This {period}?",
                month: "Month",
                monthly: "Monthly",
                numberOfSteps: "# of Steps",
                week: "Week",
                weekly: "Weekly",
            },
            todaysSteps: {
                header: "Today's Steps"
            },
            stepBreakdown: {
                header: "Step Breakdown"
            }
        }
    },
    ratings: {
        rateThisBook: "Rate this book",
        yourRating: "Your rating"
    },
    searchbar: {
        findBooks: "Find books to add to my logbook"
    },
    statsPanel: {
        approved: "Approved",
        currentStreak: "Current Streak",
        emptyGenresRead: "Complete a book to see its genre here!",
        genresRead: "Genres I've Read",
        lifetimeSteps: "Lifetime Steps",
        milestone: "Milestone",
        milestoneMedals: "Milestone Medals",
        schoolYear: "School Year ",
        sinceSchoolYear: "Since School Year ",
        todaysSteps: "Today's Steps",
        totalSteps: "Total Steps",
        findBooks: "Find books to add to my logbook",
        unapproved: "Unapproved",
    },
    stepsForm: {
        addYourSteps: "Add your steps",
        dateDescription: "Default is Today",
        dateLabel: "Date",
        lastPageLabel: "Last Page # Read",
        lastPageDescription: "Optional",
        lastPagePlaceholder: "Add page #",
        steps: "{n} step|{n} steps",
        stepsLabel: "Steps",
        stepsToMinutes: "1 step = 15 minutes"
    },
    stepsRead: {
        steps: "step|steps",
    },
    toolbar: {
        backTo: "Back to ",
        bookCollection: "My <b>Book Collection</b>",
        logbook: "My Logbook",
        moreStats: "More of <b>My Stats</b>",
        shareCollection: "Share your book collection!",
        print: "Print"
    },
    typeahead: {
        cantFind: "Can't find what you're looking for?",
        tellUs: "Tell us about your book"
    }
};

export default en;