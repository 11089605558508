import { createI18n } from "vue-i18n/index";
import translations from "@/app/config/translations";

const i18n = createI18n({
    legacy: false,
    locale: "en",
    globalInjection: true,
    messages: translations,
    warnHtmlMessage: false
});

export default i18n;
