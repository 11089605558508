import {createRouter, createWebHistory} from "vue-router";
import vuexStore from "@/core/store";
import {Mutations, Actions} from "@/core/store/enums/StoreEnums";
import routes from "@/app/config/routes";
import authService from "@/core/services/AuthenticationService";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...routes
    ],
});

router.beforeEach(async (to, from) => {
    // reset config to initial state
    vuexStore.commit(Mutations.RESET_LAYOUT_CONFIG);

    authService.validateSession();

    if (to.name === 'assessment' && !authService.isFeatureEnabled('arcreads-myirla')) return '/';
    if (to.name === 'assignments' && !authService.isFeatureEnabled('arcreads-assignments')) return '/';

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

router.beforeResolve(async to => {
    // Validate session for pages that are not public.
    if(to.meta.private) {
        await authService.validateSession();
    }
});

export default router;
