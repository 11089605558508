import {reactive, watch} from 'vue'
import Debounce from '@/core/helpers/debounce'
import eventBus from "@/core/services/eventBus";

/**
 * A simple proxy/wrapper for a vuex-like reactive store, which automatically saves itself to local storage
 * when it is modified, and restores itself from local storage when the application is reloaded.
 */

// Only serialize the store to local storage once every 500ms at most.
const debouncer = new Debounce();

const store: any = reactive({
    loaded: false,
    appData: {},
    authentication: {}
});


/* When something the store is modified or added, serialize it */
watch(store, () => {
    debouncer.debounce(() => {
        let serialized = JSON.stringify({
            version: process.env.VERSION,
            payload: store
        });
        localStorage.setItem('store', serialized);
    });
});

/* Proxy for the reactive store, so it can be transparently saved and loaded */
const storeHandler: any = {
    getStore() {
        if (store.loaded == false) {
            const serializedStore = JSON.parse(localStorage.getItem('store') || '{}');
            Object.assign(store, serializedStore.payload);
            eventBus.$emit('storeLoaded');
            store.loaded = true;
        }
        return store;
    },
    get(target, prop, receiver) {
        return Reflect.get(this.getStore(), prop, receiver);
    },
    set(target, prop, value) {
        return Reflect.set(this.getStore(), prop, value);
    }
};

const PersistentStorage = new Proxy(store, storeHandler);

export default PersistentStorage;

