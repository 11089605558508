import store from "@/core/store/PersistentStorage";
import eventBus from "@/core/services/eventBus";
import arcReadsApiClient from "@/app/services/arcReadsApiClient";
import logService from "@/app/services/LogService";
import unleash from "@/core/services/unleashClient";

const authenticationService = {
  activeAccessTokenPromise: null,
  currentTimeInSeconds() {
    return Math.floor(Date.now() / 1000);
  },
  getSettings() {
    if (this.isLoggedIn() == false) return {};
    const settings = JSON.parse(
      store.authentication.user.profile.app_settings || "{}"
    );
    return settings || {};
  },
  getSetting(prop) {
    const settings = this.getSettings();
    return settings == null ? null : settings[prop];
  },
  changeSetting(prop, value) {
    if (this.isLoggedIn() == false) return;
    const settings = this.getSettings();
    settings[prop] = value;
    store.authentication.user.profile.app_settings = JSON.stringify(settings);
    this.saveProfile();
  },
  saveProfile() {
    if (this.isLoggedIn() == false) return;
    arcReadsApiClient.put("/profile", store.authentication.user.profile);
  },
  isLoggedIn() {
    return this.currentUser() != null;
  },
  logout() {
    store.authentication = {};
    this.updateStore();
    window.location.href =
      process.env.VUE_APP_ARCID_HOST +
      "/logout?client_id=" +
      process.env.VUE_APP_OAUTH_CLIENT_ID +
      "&post_logout_redirect_uri=" +
      window.location.origin;
  },
  currentUser() {
    return store.authentication.user || null;
  },
  async validateSession() {
    if (!store.authentication.expires_at) {
      return;
    }

    if (this.currentUser() != null) {
      // If the current time is past the expiration time, 401 redirect the user.
      if (this.currentTimeInSeconds() > store.authentication.expires_at) {
        return;
      }

      // If our user is within 30 seconds of token expiration, refresh the token data to keep the session valid.
      if (store.authentication.expires_at <= this.currentTimeInSeconds() + 30) {
        this.getNewAccessToken();
      }
    }
  },
  loginSSO(authorizationCode) {
    const redirectUri = window.location.origin + "/oauth/arcid";
    this.clearStore();
    return arcReadsApiClient
      .post("login", {
        code: authorizationCode,
        redirect_uri: redirectUri,
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      })
      .then((response) => {
        if (response && response.data) {
          this.processLoginResponse(response);
          return response;
        }
        return false;
      })
      .catch((e) => {
        console.log("caught ", e);
      });
  },

  /** Obtains a new auth token with the refresh token */
  getNewAccessToken() {
    if (!store.authentication.refreshToken) {
      return;
    }

    // We do not want axios interceptor to continuously call this request before even one completes, only allow one at a time active.
    if (this.activeAccessTokenPromise) {
      return;
    }

    this.activeAccessTokenPromise = arcReadsApiClient
      .post("/refresh", {
        refresh_token: store.authentication.refreshToken,
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      })
      .then((response) => {
        if (response && response.data) {
          this.processLoginResponse(response);
        }
        this.activeAccessTokenPromise = null;
      })
      .catch(() => {
        this.activeAccessTokenPromise = null;
      });
  },

  processLoginResponse(response) {
    return new Promise((resolve) => {
      if (response.data.hasArcReads == false) {
        window.location.href = "/access-check";
        return;
      }

      const expiresInSeconds = response.data.expires_in ?? 900;

      logService.clearCombinedReadingLog();
      store.authentication.accessToken = response.data.access_token;
      store.authentication.refreshToken = response.data.refresh_token;

      store.authentication.expires_at =
        this.currentTimeInSeconds() + expiresInSeconds;
      store.authentication.user = response.data.me;
      if (response.data.me.districtId) unleash.setContextField("districtId", response.data.me.districtId);
      if (response.data.me.currentInstance?.gradeId) unleash.setContextField("gradeId", response.data.me.currentInstance.gradeId);
      if (!store.authentication.features) store.authentication.features = [];
      store.authentication.authenticating = false;
      store.authentication.user.profile = response.data.profile;
      store.authentication.user.readingSteps = response.data.readingSteps;
      store.authentication.user.assessments = response.data.assessments;
      if (response.data.me.currentInstance) {
        if (store.authentication.features) {
          const featureIndex = store.authentication.features.findIndex((feature) => feature === 'arcreads-myirla');
          if (featureIndex === -1) store.authentication.features.push('arcreads-myirla');
        }
      }
      else {
        if (store.authentication.features) {
            const featureIndex = store.authentication.features.findIndex((feature) => feature === 'arcreads-myirla');
            if (featureIndex !== -1) store.authentication.features.splice(featureIndex, 1);
        }
      }

      // Make the dates consistent with arc reads date formats
      store.authentication.user.readingSteps.forEach((entry) => {
        entry.entry_date = entry.entry_date + " 00:00:00";
      });

      // To support automated tests, serialize the updates immediately (do not debounce)
      logService.clearCombinedReadingLog();
      this.updateStore();

      eventBus.$emit("loggedIn");
      resolve(response.data);
    });
  },

  isFeatureEnabled(featureNameToCheck) {
    let enabled = false;
    if (store.authentication.features) {
      const foundFeature = store.authentication.features.find(
        (feature) => feature === featureNameToCheck
      );
      return foundFeature;
    }
    // Or false, if nothing matches.
    return enabled;
  },

  clearStore() {
    store.loaded = false;
    store.appData = {};
    store.authentication = {};
    this.updateStore();
  },

  updateStore() {
    localStorage.setItem(
      "store",
      JSON.stringify({
        version: process.env.VERSION,
        payload: store,
      })
    );
  },
};

unleash.on('update', () => {
    let user = authenticationService.currentUser()
    const accessAssignments = unleash.isEnabled('arcreads-assignments');
    if (accessAssignments && (user && user.currentInstance)) {
        if (store.authentication.features) {
            const featureIndex = store.authentication.features.findIndex((feature) => feature === 'arcreads-assignments');
            if (featureIndex === -1) store.authentication.features.push('arcreads-assignments');
        }
    }
    else {
        if (store.authentication.features) {
            const featureIndex = store.authentication.features.findIndex((feature) => feature === 'arcreads-assignments');
            if (featureIndex !== -1) store.authentication.features.splice(featureIndex, 1);
        }
    }
});

export default authenticationService;
