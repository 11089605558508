import store from '@/core/store/PersistentStorage';
import moment from "moment-with-locales-es6";
import config from "@/app/config";
import authenticationService from "@/core/services/AuthenticationService";
import arcReadsApiClient from "@/app/services/arcReadsApiClient";
import PersistentStorage from "@/core/store/PersistentStorage";
import apiClient from "@/core/services/apiClient";

const logService:any = {
    clearCombinedReadingLog() {
        (window as any).combinedReadingLog = null;
    },
    /* Returns {current:int, longest:int} */
    getReadingStreak() {
        let longestStreak: any = 0;
        let currentStreak: any = null;
        let stepsToday = 0;

        if (!store.appData.log) return {current: 0, longest: 0};

        // Flatten reading logs
        let logData: any = [].concat(...store.appData.log.map(({log_entries}) => log_entries || []));

        // Sort by date descending
        logData.sort((a, b) => (a.entry_date < b.entry_date) ? 1 : -1)

        // Consolidate into an array of sorted dates and total sum of steps for that date
        const reading_days: any = [];
        for (const entry of logData) {
            if (reading_days.length == 0 || reading_days[reading_days.length - 1].date != entry.entry_date) {
                reading_days.push({
                    date: entry.entry_date,
                    steps: entry.steps
                });
            } else {
                reading_days[reading_days.length - 1].steps += entry.steps;
            }
        }

        // Find the streaks
        const minStepsPerDay = config.dailyStepsForStreak;
        let lastEntryDate = moment().format('YYYY-MM-DD 00:00:00');
        let dayCounter = 0;
        let today = moment();

        for (const day of reading_days) {
            const lastEntryMoment = moment(lastEntryDate);
            const thisEntryMoment = moment(day.date);
            const daysSinceLastEntry = lastEntryMoment.diff(thisEntryMoment, 'days');
            const isToday = thisEntryMoment.diff(today, 'days') == 0;

            // Before we do anything, add today's steps to the output struct - needed so we can determine later
            // if the streak has been continued yet today.
            if (isToday) {
                stepsToday = day.steps;
            }

            // Skip over future log entries, although there shouldn't be any it will mess up the calculation
            // if there are.
            if (daysSinceLastEntry < 0) continue;

            // If more than 1 day elapsed since the last entry, streak has ended.
            if (daysSinceLastEntry > 1) {
                // Streak is broken - tally it up!
                if (currentStreak == null) currentStreak = dayCounter;
                if (dayCounter > longestStreak) longestStreak = dayCounter;
                dayCounter = 1;
            }

            // If only one day elapsed, streak (may have) continued.
            else if (daysSinceLastEntry <= 1) {
                // Streak continued if min steps were read
                if (day.steps >= minStepsPerDay) {
                    dayCounter++;
                }
                // Streak ends if not enough steps
                else {
                    // Today is an exception - don't end the streak yet because we might still read more later today.
                    if (!isToday) {
                        if (currentStreak == null) currentStreak = dayCounter;
                        if (dayCounter > longestStreak) longestStreak = dayCounter;
                        dayCounter = 1;
                    }
                }
            }
            lastEntryDate = day.date;
        }
        if (currentStreak == null) currentStreak = dayCounter;
        if (dayCounter > longestStreak) longestStreak = dayCounter;
        return {
            current: currentStreak,
            longest: longestStreak,
            stepsToday,
            minStepsPerDay
        };
    },
    refreshReadingLog() {
        if (authenticationService.isLoggedIn()) {
            this.clearCombinedReadingLog();
            store.appData.log = store.appData.log || [];
            return arcReadsApiClient.get("/log").then(response => {
                this.clearCombinedReadingLog();
                store.appData.log = response.data;
            });
        }
    },
    addBook(bookData) {
        if (bookData.author == '') bookData.author = 'Unknown Author';
        return arcReadsApiClient.post('/log', bookData).then(response => {
            this.clearCombinedReadingLog();
            const bookToUpdate = store.appData.log.findIndex(x => x.id == response.data.id);
            if (bookToUpdate != -1) {
                // And move to the top
                store.appData.log.splice(bookToUpdate, 1);
                store.appData.log.unshift(response.data);
            } else {
                // Or add the new one to the top
                store.appData.log.unshift(response.data);
            }
        });
    },
    saveBook(book) {
        arcReadsApiClient.update('/books', book);
        this.clearCombinedReadingLog();
    },
    setStatus(book, status) {
        book.status = status;
        book.updated_at = moment().format('YYYY-MM-DD HH:mm:ss');
        this.saveBook(book);
    },
    deleteEntry(book, entry) {
        const entry_id = entry.id;
        if (entry_id == null) return;       // Can't delete it if it wasn't saved yet
        const idx = book.log_entries.findIndex((item) => {
            return item.id == entry_id;
        });
        if (idx == -1) return;              // Can't delete a non-existing entry
        arcReadsApiClient.remove("/log", entry);
        this.clearCombinedReadingLog();
        book.log_entries.splice(idx, 1);
    },
    findBookByIsbn(isbn) {
        return null;
    },
    getGenreStats(maxGenres = 5) {
        const genres = {};
        if (!store.appData.log) return [];

        for (const bookInstance of store.appData.log) {
            if (bookInstance.status != 'Complete') continue;
            let genre = (bookInstance.book ? bookInstance.book.genre : null) || 'N/A';
            genres[genre] = (genres[genre] || 0) + 1;
        }

        const sortedList: any = [];
        for (const genre of Object.keys(genres)) {
            sortedList.push({
                name: genre,
                value: genres[genre]
            });
        }

        sortedList.sort((a, b) => (a.value < b.value) ? 1 : -1)

        return sortedList.slice(0, maxGenres);
    },
    getUnapprovedLogEntries() {
        // If we're not in a school year, or don't have one yet, we can't have unapproved entries, since the logic is
        // "... the unapproved steps from the log_entries table, but only for those log_entries that happened
        // between the first and last day of the current school year." (See comments in Jira AR-323)
        
        if (authenticationService.currentUser().studentId > 0 && store.appData.thisSchoolYear == null) {
            return [];
        }

        let unapprovedArcReadsEntries = JSON.parse(JSON.stringify([...(PersistentStorage.appData.log || [])]));
        unapprovedArcReadsEntries.forEach(bookInstance => {
            bookInstance.log_entries = bookInstance.log_entries.filter(entry => {
                let entryDate = entry.entry_date.substring(0, 10);
                return entry.review_status == 'Submitted'
                    && (
                        store.appData.thisSchoolYear == null
                        || (entryDate > store.appData.thisSchoolYear.firstDayOfSchool && entryDate <= store.appData.thisSchoolYear.lastDayOfSchool)
                    );
            });
        });
        return unapprovedArcReadsEntries;
    },
    // Returns a combined SchoolPace + ARC Reads reading log, to be used for stats and calculations.
    // NOTE: CANNOT USE THIS FOR SHOWING BOOK TITLES, since SchoolPace does not store that detail.
    getCombinedReadingLog() {
        let cachedLog = (window as any).combinedReadingLog || null;
        // Commented out for now - This used to cache the results, since this gets called for every reactive update. But caching was not
        // working correctly and was super tricky to debug due to all the ajax promises. Disabling for now.

        // if (cachedLog != null) return cachedLog;

        // Here, we create a fake book instance to hold all of the schoolpace reading data, which is already in the format used by
        // arc reads. Then we just concatenate that onto the actual list of real book instances and the stats panels will all
        // calculate the combined steps correctly.

        // ALSO NOTE: Schoolpace data already contains all approved arc reads log_entries rows. So we need to exclude them below.

        let fakeBookInstanceForSchoolPaceReadingPractice = {
            log_entries: authenticationService.currentUser().readingSteps || []
        };

        // Concatenate the schoolpace reading logs together with the unapproved ARC Reads ones...
        cachedLog = [fakeBookInstanceForSchoolPaceReadingPractice, ...this.getUnapprovedLogEntries()];
        (window as any).combinedReadingLog = cachedLog;
        return cachedLog;
    },
    loadSchoolYears() {
        // Put the school year in store.appData so it doesnt get lost on token refreshes (user / auth storage will change)
        if (store.authentication.user.studentId > 0) {
            apiClient.get("/school_years?schoolId=" + store.authentication.user.schoolId).then(response => {
                this.schoolYearData = response.data;
                let schoolYearId = authenticationService.currentUser().profile?.student?.currentInstance?.schoolYearId;
                if (schoolYearId > 0) {
                    this.clearCombinedReadingLog();
                    store.appData.thisSchoolYear = response.data.find(x => x.schoolYearId == schoolYearId);
                }
            });
        }
    }
}

logService.schoolYearData = [];

export default logService;