const arcidService = {
    // Call to ensure the user stays logged in to ARC ID.
    extendSession() {
        const xhr = new XMLHttpRequest();
        const url = process.env.VUE_APP_ARCID_HOST + '/api/v1/sessions/me';
        xhr.open('HEAD', url);
        xhr.withCredentials = true;
        xhr.send();
    }
};

export default arcidService;
