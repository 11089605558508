/*eslint func-names: ["error", "never"]*/
import axios from 'axios';
import store from '@/core/store/PersistentStorage';
import eventBus from "@/core/services/eventBus";

const arcReadsApiClient: any = axios.create({
    baseURL: (process.env.VUE_APP_SERVERLESS == true) ? '/mock_api' : (process.env.VUE_APP_ARCREADS_API_HOST + '/arc-reads')
});

arcReadsApiClient.allowedCodes = [];

arcReadsApiClient.token = null;

if (process.env.VUE_APP_SERVERLESS == true) {
    arcReadsApiClient.post = function (a) {
        return arcReadsApiClient.get(a);
    };
}

/* When chained into a request, this function allows a specific HTTP response code, or array of codes, without throwing the "A system error occurred" notification in the UI.
   Use like this: apiClient.allow([422, 421]).post(...)

   This is useful if you want specific server errors to be handled within your component, such as for form validation.
 */
arcReadsApiClient.allow = function (errorCodes) {
    if (Array.isArray(errorCodes)) {
        arcReadsApiClient.allowedCodes = errorCodes;
    } else {
        arcReadsApiClient.allowedCodes = [errorCodes];
    }
    return this;
};

arcReadsApiClient.download = function (url) {
    return arcReadsApiClient({url, method: 'GET', responseType: 'blob'});
};

arcReadsApiClient.create = function (endpoint, data) {
    return this.post(endpoint, data);
};

arcReadsApiClient.update = function (endpoint, data) {
    return this.put(endpoint + '/' + data.id, data);
};

arcReadsApiClient.remove = function (endpoint, data) {
    return this.delete(endpoint + '/' + data.id);
};

arcReadsApiClient.interceptors.request.use(request => {
    if (store.authentication.accessToken) {
        request.headers.Authorization = `Bearer ${store.authentication.accessToken}`;
    }
    return request;
});

arcReadsApiClient.interceptors.response.use(response => {
        return response;
    }, async error => {
        if (error.request.status == 401) {
            eventBus.$emit('logout');
        } else {
            // If error was not 401 just reject as is
            throw error;
        }
    }
);

export default arcReadsApiClient;
