export default class Debounce {
    private timeoutId: any;

    public debounce(fn: Function, ms = 500) {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
            fn();
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }, ms)
    }
}
