import { createApp } from "vue";
import App from "./App.vue";

import router from "@/core/router";
import store from "@/core/store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import { useScriptTag } from "@vueuse/core";

//imports for app initialization
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

import unleash from '@/core/services/unleashClient';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(BootstrapIconsPlugin);

initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");

if (process.env.VUE_APP_ENVIRONMENT !== 'local') {
    const plausibleEnvironment = (process.env.VUE_APP_ENVIRONMENT !== 'pro') ? `-${process.env.VUE_APP_ENVIRONMENT}` : '';
    // Plausible
    useScriptTag(
        `//webanalytics.americanreading.com/js/script.js`,
        // on script tag loaded.
        (el: HTMLScriptElement) => {
            // do something
        }, {
        defer: true,
        attrs: {
            'domain': `arcreads${plausibleEnvironment}.americanreading.com`
        }
    });
}

// Start the background polling
unleash.start();
