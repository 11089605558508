import {RouteRecordRaw} from "vue-router";

const routes = [
    {
        path: "/",
        redirect: "/home",
        component: () => import("@/core/layout/AuthenticatedLayout.vue"),
        children: [
            {
                path: "/home",
                name: "home",
                component: () => import("@/app/pages/Home.vue"),
            },
            {
                path: "/logbook",
                name: "logbook",
                component: () => import("@/app/pages/Logbook.vue"),
            },
            {
                path: "/profile",
                name: "profile",
                component: () => import("@/app/pages/Profile.vue"),
            },
            {
                path: "/stats",
                name: "stats",
                component: () => import("@/app/pages/stats/Stats.vue"),
            },
            {
                path: "/collection",
                name: "collection",
                component: () => import("@/app/pages/collection/BookCollection.vue"),
            },
            {
                path: "/myirla",
                name: "assessment",
                component: () => import("@/app/pages/Assessment.vue"),
            },
            {
                path: "/assignments",
                name: "assignments",
                component: () => import("@/app/pages/Assignments.vue"),
            },
            {
                path: '/assignments/unit-:id',
                name: 'unit',
                component: () => import('@/app/pages/Unit.vue'),
            },
        ],
        meta: {
            private: true
        }
    },
    {
        path: "/public",
        component: () => import("@/core/layout/PublicLayout.vue"),
        children: [],
    },
    {
        path: "/",
        component: () => import("@/core/layout/PublicLayout.vue"),
        children: [
            {
                path: "/oauth/arcid",
                name: "arcid",
                component: () => import("@/core/pages/Arcid.vue"),
            },
        ],
    },
    {
        path: "/",
        component: () => import("@/app/layouts/Auth.vue"),
        children: [
            {
                path: "/login",
                name: "login",
                component: () =>
                    import("@/app/pages/authentication/Login.vue"),
            },
            {
                path: "/logout",
                name: "logout",
                component: () =>
                    import("@/app/pages/authentication/Logout.vue"),
            },
            {
                path: "/access-check",
                name: "noaccess",
                component: () =>
                    import("@/app/pages/authentication/NoAccess.vue"),
            },
        ],
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/app/pages/error/Error404.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

export default routes;