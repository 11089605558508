/*eslint func-names: ["error", "never"]*/
import axios from 'axios';
import store from '@/core/store/PersistentStorage';
import eventBus from "@/core/services/eventBus";

const apiClient: any = axios.create({
    baseURL: (process.env.VUE_APP_SERVERLESS == true) ? '/mock_api' : (process.env.VUE_APP_API_HOST + '/api')
});

apiClient.allowedCodes = [];

apiClient.token = null;

if (process.env.VUE_APP_SERVERLESS == true) {
    apiClient.post = function (a) {
        return apiClient.get(a);
    };
}

/* When chained into a request, this function allows a specific HTTP response code, or array of codes, without throwing the "A system error occurred" notification in the UI.
   Use like this: apiClient.allow([422, 421]).post(...)

   This is useful if you want specific server errors to be handled within your component, such as for form validation.
 */
apiClient.allow = function (errorCodes) {
    if (Array.isArray(errorCodes)) {
        apiClient.allowedCodes = errorCodes;
    } else {
        apiClient.allowedCodes = [errorCodes];
    }
    return this;
};

apiClient.download = function (url) {
    return apiClient({url, method: 'GET', responseType: 'blob'});
};

apiClient.create = function (endpoint, data) {
    return this.post(endpoint, data);
};

apiClient.update = function (endpoint, data) {
    return this.put(endpoint + '/' + data.id, data);
};

apiClient.remove = function (endpoint, data) {
    return this.delete(endpoint + '/' + data.id);
};

apiClient.interceptors.request.use(request => {
    if (store.authentication.accessToken) {
        request.headers.Authorization = `Bearer ${store.authentication.accessToken}`;
    }
    return request;
});

apiClient.interceptors.response.use(response => {
        return response;
    }, async error => {
        if (error.request.status == 401) {
            eventBus.$emit('logout');
        } else {
            // If error was not 401 just reject as is
            throw error;
        }
    }
);

export default apiClient;
