import apiClient from "@/core/services/apiClient";
import authenticationService from "@/core/services/AuthenticationService";
import store from "@/core/store/PersistentStorage";
import bookshelfApiClient from "@/app/services/bookshelfApiClient";
import arcReadsApiClient from "@/app/services/arcReadsApiClient";
import PersistentStorage from "@/core/store/PersistentStorage";
import logService from "@/app/services/LogService";

const bookService = {
    readingLevel(book, locale = 'en') {
        if (!book) return '';
        // Assume we were passed an actual book object
        let bookData = book;
        // But check to see if were passed a book instance (with user-entered data and no reading level)
        if (book.book != null) {
            bookData = book.book;
        }
        return book.language == "es" ? bookData.reading_level_enil : bookData.reading_level_irla;
    },
    coverImage(book) {
        return (book && book.isbn) ? process.env.VUE_APP_COVERIMAGE_URL + '/128/' + book.isbn.trim() + '.jpg' : '/media/books/no-cover.jpg';
    },
    bookshelfURL(sku) {
        let url = new URL(process.env.VUE_APP_ARCID_HOST + '/authorize');
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('client_id', process.env.VUE_APP_BOOKSHELF_CLIENT_ID);
        url.searchParams.append('redirect_uri', process.env.VUE_APP_BOOKSHELF_WEB_HOST + '/oauth/arcid');
        url.searchParams.append('state', btoa(process.env.VUE_APP_BOOKSHELF_WEB_HOST + '/#' + sku));
        return url.toString();
    },
    canLinkToBookshelf(sku) {
        return store.appData.bookshelfAccess.includes(sku);
    },
    getBookshelfSubscriptions() {
        return store.appData.bookshelfAccess || [];
    },
    loadBookshelfSubscriptions() {
        const user = authenticationService.currentUser();
        if (user.studentId > 0) {
            return this.loadBookshelfSubscriptionsForStudent(user);
        }
        return this.loadBookshelfSubscriptionsForUser(user);
    },
    loadBookshelfSubscriptionsForUser(user) {
        apiClient.get('/schools').then(response => {
            if (!response.data) return;
            let schoolIds = response.data.map(a => a.schoolId).join("%2C");     // %2C = hex for comma
            store.appData.bookshelfAccess = [];
            bookshelfApiClient.get('/v1/library/schools/' + schoolIds).then(response => {
                store.appData.bookshelfAccess = response.data;
            });
        });
    },
    loadBookshelfSubscriptionsForStudent(student) {
        store.appData.bookshelfAccess = [];
        const studentInstance = student.currentInstance || null;

        let gradeParam = "";
        if (studentInstance != null) {
            let gradeId = studentInstance.grade.gradeId;
            gradeParam = "?gradeId=" + gradeId;
        }

        let schoolId = student.schoolId;
        bookshelfApiClient.get('/v1/library/schools/' + schoolId + gradeParam).then(response => {
            store.appData.bookshelfAccess = response.data;
        });
    },
    getRecommendations() {
        return store.appData.recommendations || [];
    },
    loadRecommendations() {
        apiClient.get('/book-groups/recommend').then(response => {
            store.appData.recommendations = response.data;
        });
    },
    loadGenres(irla = null, enil = null) {
        arcReadsApiClient.get("/genres?irla=" + irla + "&enil=" + enil).then(response => {
            store.appData.genres = response.data;
        });
    },
    getGenres() {
        return store.appData.genres || [];
    },
    searchTitles(query, limit = 25, lang = null) {
        let gradeFilter = '';
        let langFilter = '';
        const studentInstance = authenticationService.currentUser().currentInstance || null;
        if (studentInstance != null) {
            gradeFilter = '&gradeAppropriate=' + studentInstance.grade.group;
        }
        if (lang != null) {
            langFilter = '&language='+lang;
        }
        return apiClient.get('/books/search?query=' + query + '&limit=' + limit + gradeFilter + langFilter);
    },
    formatTitle(bookTitle) {
        const articles = ['A', 'An', 'The'];
        let parts = bookTitle.split(", ");
        let partCount = parts.length;
        if (partCount == 1) return bookTitle;  // Fastest exit
        if (articles.includes(parts[partCount - 1])) {  // Next fastest
            for (const article of articles) {
                if (bookTitle.endsWith(", " + article)) {
                    return article + ' ' + bookTitle.slice(0, -1 * (article.length + 2));
                }
            }
        }
        return bookTitle;
    },
    getBorderColor(level) {
        switch (level) {
            default:
                return ''
            case 'lam':
            case 'rtm':
            case 'icp':
                return {
                    name: '-rtm',
                    color: '#a064b9'
                }
            case '1y':
            case '2y':
            case '3y':
                return {
                    name: '-y',
                    color: '#ffcc00'
                }
            case '1g':
            case '2g':
                return {
                    name: '-g',
                    color: '#39b54a'
                }
            case '1b':
            case '2b':
                return {
                    name: '-b',
                    color: '#0072bc'
                }
            case '1r':
            case '2r':
                return {
                    name: '-r',
                    color: '#e50000'
                }
            case 'wt':
            case 'wt-alt':
                return {
                    name: '-w',
                    color: '#ececec'
                }
            case "bk":
                return {
                    name: '-bk',
                    color: '#000000'
                }
            case "or":
                return {
                    name: '-o',
                    color: '#ff9933'
                }
            case "pu":
                return {
                    name: '-p',
                    color: '#663399'
                }
            case "br":
            case "1br":
            case "2br":
                return {
                    name: '-br',
                    color: '#c27514'
                }
            case "si":
                return {
                    name: '-si',
                    color: '#c4c9cc'
                }
            case "gl":
            case "1gl":
            case "2gl":
                return {
                    name: '-gl',
                    color: '#fec35a'
                }
        }
    },
    stepsAwaitingApproval() {
        let stepsAwaitingApproval = 0;
        let log = logService.getUnapprovedLogEntries();
        if (log) {
            log.map(book => {
                let logEntries = book.log_entries
                logEntries.map(entry => {
                    if (entry.review_status === 'Submitted') {
                        stepsAwaitingApproval += entry.steps
                    }
                    return ''
                });
                return ''
            });
        }
        return stepsAwaitingApproval
    },
    stepsTotal() {
        let total = 0;
        let log = [...logService.getCombinedReadingLog()];
        log.forEach(book => {
            (book.log_entries || []).forEach(entry => {
                total += entry.steps;
            });
        });
        return total;
    },
};

export default bookService;
