/*
* Language translations are in their own files so they can be version-controlled separately.
* This will make it easier to know whether or not a change or addition to the english text has been translated yet,
* simply by checking last modified dates.
*/

import en from "./lang/en";
import es from "./lang/es";

const translations = {en, es};

export default translations;